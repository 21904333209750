/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* You can add global styles to this file, and also import other style files */



@import "~@angular/material/theming";

// Include non-theme styles for core.
@include mat-core();

$mat-custom-theme: (
  50 : #6c9d26,
  100 : #6c9d26,
  200 : #6c9d26,
  300 : #6c9d26,
  400 : #6c9d26,
  500 : #6c9d26,
  600 : #6c9d26,
  700 : #6c9d26,
  800 : #6c9d26,
  900 : #6c9d26,
  A100 : #6c9d26,
  A200 : #6c9d26,
  A400 : #6c9d26,
  A700 : #6c9d26,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

$custom-secondary-orange: (
    50 : #f98000,
    100 : #f98000,
    200 : #f98000,
    300 : #f98000,
    400 : #f98000,
    500 : #f98000,
    600 : #f98000,
    700 : #f98000,
    800 : #f98000,
    900 : #f98000,
    A100 : #f98000,
    A200 : #f98000,
    A400 : #f98000,
    A700 : #f98000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Define a theme.
$primary: mat-palette($mat-custom-theme);
$accent:  mat-palette($custom-secondary-orange, A200, A100, A400);

$theme: mat-light-theme($primary, $accent);

// Include all theme styles for the components.
@include angular-material-theme($theme);


.spinner-container {
  position: fixed;
  height: 300px;
  width: 345px;
  display: flex;
  background: white;
  margin-top: 70px;
  z-index: 1;
  opacity: 0.5;
  justify-content: center;
}

.margin-y {
  margin-top: 60px;
}